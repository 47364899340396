<template>
  <sdPageHeader title="Add campaign default values" />
  <GeneralForm :formData="form" :initialData="initialData" :otherFormData="otherForm">
    <template v-slot:content-setting>
      <div class="create-account-form">
        <a-row :gutter="15">
          <a-col :lg="12" :xs="24">
            <sdHeading as="h4">Step 1 Details</sdHeading>
            <a-form-item name="step1_title" label="Title">
              <a-input  v-model:value="form.step1_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="step1_description" label="Description">
              <a-textarea  v-model:value="form.step1_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
            <a-form-item name="step1_button_text" label="Button Text">
              <a-input  v-model:value="form.step1_button_text" placeholder="Enter button text" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :xs="24">
            <sdHeading as="h4">Step 2 Details</sdHeading>
            <a-form-item name="step2_title" label="Title">
              <a-input  v-model:value="form.step2_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="step2_description" label="Description">
              <a-textarea  v-model:value="form.step2_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
          </a-col>
          <a-col style="margin-top: 30px;" :lg="12" :xs="24">
            <sdHeading as="h4">Success details</sdHeading>
            <a-form-item name="success_title" label="Title">
              <a-input v-model:value="form.success_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="success_btn_title" label="Button Title">
              <a-input v-model:value="form.success_btn_title" placeholder="Enter success button title" />
            </a-form-item>
            <a-form-item name="success_url" label="Button URL">
              <a-input type="url" v-model:value="form.success_url" placeholder="Enter success button url" />
              <small>[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small>
            </a-form-item>
            <a-form-item name="success_description" label="Description">
              <a-textarea v-model:value="form.success_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
          </a-col>
          <a-col style="margin-top: 30px;" :lg="12" :xs="24">
            <sdHeading as="h4">Failure details</sdHeading>
            <a-form-item name="fail_title" label="Title">
              <a-input v-model:value="form.fail_title" placeholder="Enter Title" />
            </a-form-item>
            <a-form-item name="fail_btn_title" label="Button Title">
              <a-input v-model:value="form.fail_btn_title" placeholder="Enter failure button title" />
            </a-form-item>
            <a-form-item name="fail_url" label="Button URL">
              <a-input type="url" v-model:value="form.fail_url" placeholder="Enter failure button url" />
              <small>[firstname]&nbsp;&nbsp;[lastname]&nbsp;&nbsp;[email]&nbsp;&nbsp;[city]&nbsp;&nbsp;[phone]&nbsp;&nbsp;[zip]&nbsp;&nbsp;[address]</small>
            </a-form-item>
            <a-form-item name="fail_description" label="Description">
              <a-textarea v-model:value="form.fail_description" :rows="4" placeholder="Enter description" />
            </a-form-item>
          </a-col>
        </a-row>
        <sdHeading as="h4">Card Images</sdHeading>
        <a-row :gutter="15">
          <a-col :lg="6" :xs="24">
            <a-form-item name="card_front" label="Card Step 1 Image">
              <a-input
                type="file"
                id="card-front-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'card_front')"
              />
            </a-form-item>
            <div v-if="card_front_url" class="img-thumb-cls">
              <img alt="Card Front Image" :src="card_front_url"/>
            </div>
          </a-col>
          <a-col :lg="6" :xs="24">
            <a-form-item name="card_step2" label="Card Step 2 Image">
              <a-input
                type="file"
                id="card-back-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'card_step2')"
              />
            </a-form-item>
            <div v-if="card_step2_url" class="img-thumb-cls">
              <img alt="Card Step2 Image" :src="card_step2_url"/>
            </div>
          </a-col>
          <a-col :lg="6" :xs="24">
            <a-form-item name="card_winning" label="Winning Image">
              <a-input
                type="file"
                id="card-back-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'card_winning')"
              />
            </a-form-item>
            <div v-if="card_winning_url" class="img-thumb-cls">
              <img alt="Card Winning Image" :src="card_winning_url"/>
            </div>
          </a-col>
          <a-col :lg="6" :xs="24">
            <a-form-item name="card_back" label="Card Back Image">
              <a-input
                type="file"
                id="card-back-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'card_back')"
              />
            </a-form-item>
            <div v-if="card_back_url" class="img-thumb-cls">
              <img alt="Card Back Image" :src="card_back_url"/>
            </div>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="loser_popup_image" label="Loser Popup Image">
              <a-input
                type="file"
                id="card-back-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'loser_popup_image')"
              />
            </a-form-item>
            <div v-if="loser_popup_url" class="img-thumb-cls">
              <img alt="Loser Popup Image" :src="loser_popup_url"/>
            </div>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="bg_image" label="Background Image">
              <a-input
                type="file"
                accept="image/*"
                id="bg-image-file"
                v-on:change="onImageChange($event, 'bg_image')"
              />
            </a-form-item>
            <div v-if="bg_image_url" class="img-thumb-cls">
              <img alt="Background Image" :src="bg_image_url"/>
            </div>
          </a-col>
          <a-col :lg="8" :xs="24">
            <a-form-item name="scratch_cursor_image" label="Scratch Cursor Image">
              <a-input
                type="file"
                accept="image/*"
                id="bg-image-file"
                v-on:change="onScratchCursorImage"
              />
            </a-form-item>
            <div v-if="scratch_cursor_image_url" class="img-thumb-cls">
              <img alt="Scratch Cursor Image" :src="scratch_cursor_image_url"/>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="15">
              <a-col :lg="24" :xs="24">
                  <sdHeading as="h4">Prize Block</sdHeading>
                  <a-row :gutter="25" style="margin-top: 15px;">
                  <a-col :lg="12" :xs="24">
                    <a-checkbox
                      name="prize_popup_status"
                      v-model:checked="form.prize_popup_status"
                    >
                      <strong>Active</strong>
                    </a-checkbox>
                  </a-col>
                </a-row>
                  <a-row :gutter="25" style="margin-bottom: 0;">
                    <a-col :lg="12" :xs="24">
                      <a-form-item name="prize_block_title" label="Popup Title">
                        <a-input  v-model:value="form.prize_block_title" placeholder="Enter Title" />
                      </a-form-item>
                    </a-col>
                    <a-col :lg="12" :xs="24">
                      <a-form-item name="prize_button_text" label="Prize Button Text">
                        <a-input  v-model:value="form.prize_button_text" placeholder="Enter button text" />
                      </a-form-item>
                    </a-col>
                  </a-row>
              </a-col>
          </a-row>
          <a-row :gutter="15">
            <a-col :lg="24" :xs="24">
                <sdHeading as="h4">Close Detail</sdHeading>
                <a-row :gutter="25" style="margin-bottom: 0;">
                  <a-col :lg="24" :xs="24">
                    <a-form-item name="close_redirect_url" label="Redirect URL">
                      <a-input  v-model:value="form.close_redirect_url" placeholder="Enter Redirect URL" />
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24" :xs="24" style="margin-top: 15px;">
                    <a-form-item name="close_message" label="Message">
                      <a-textarea v-model:value="form.close_message" :rows="4" placeholder="Enter Message" />
                    </a-form-item>
                  </a-col>
              </a-row>
            </a-col>
          </a-row>
      </div>
    </template>
    <template v-slot:winners-prizes>
      <a-row :gutter="15" style="margin-bottom: 0;">
        <a-col style="margin-bottom: 26px;" :lg="12" :xs="24">
          <a-form-item name="scratch_percentage" label="Scratch Percentage">
            <a-input type="number" v-model:value="form.scratch_percentage" placeholder="Enter scratch percentage" />
          </a-form-item>
        </a-col>
        <a-col style="margin-bottom: 26px;" :lg="12" :xs="24">
          <a-form-item name="prize_title" label="Prize Title">
            <a-input v-model:value="form.prize_title" placeholder="Enter prize title" />
          </a-form-item>
        </a-col>
        <a-col style="margin-bottom: 26px;" :lg="12" :xs="24">
          <a-form-item name="winning_percentage" label="Winning Percentage">
            <a-input type="number" v-model:value="form.winning_percentage" placeholder="Enter winning percentage" />
          </a-form-item>
        </a-col>
      </a-row>
    </template>
  </GeneralForm>
</template>

<script>
import _ from "lodash";
import VueTypes from 'vue-types';

import GeneralForm from "@/components/generalComponent/admin/themeDefaultForm.vue";

import {getImageHeightWidth} from "@/services/helperFunctions.js";
export default {
  name: "SetupCampaign",
  components: { GeneralForm },
  props: {defaultData: VueTypes.object.def({})},
  data() {
    return {
      initialData: {},
      form: {
        step1_title: "",
        step1_button_text: "",
        step1_description: "",
        step2_title: "",
        step2_description: "",
        success_title: "",
        success_url: "",
        success_btn_title: "",
        success_description: "",
        fail_title: "",
        fail_url: "",
        fail_btn_title: "",
        fail_description: "",
        prize_block_title: "",
        prize_button_text: "",
        prize_popup_status: true,
        card_front: null,
        card_back: null,
        card_step2: null,
        card_winning: null,
        loser_popup_image: null,
        bg_image: null,
        scratch_cursor_image: null,
        winning_percentage: "",
        prize_title: "",
        scratch_percentage: "",
        close_redirect_url: "",
        close_message: "",
      },
      otherForm: {
        meta_image_url: null,
        campaign_image_url: null,
        selectedOs: [],
        selectedDevice: [],
        selectedBrowser: [],
        selectedCountry: [],
      },
      bg_image_url: "",
      scratch_cursor_image_url: "",
      card_back_url: "",
      card_front_url: "",
      card_step2_url: "",
      card_winning_url: "",
      loser_popup_url: "",
    };
  },
  computed: {
    countries: function() {
      return this.$store.getters.countries;
    },
    stepView: function() {
      return this.$refs.stepView;
    },
  },
  methods: {
    getValue(value) {
      if (value == null) {
        return "";
      } else if (_.isString(value)) {
        if (value == "true" || value == "false") {
          return JSON.parse(value);
        }
        return value;
      } else if (_.isNumber(value) || _.isArray(value)) {
        return value;
      } else {
        return JSON.stringify(value);
      }
    },
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "bg_image") {
        this.bg_image_url = blobURL;
        this.form.bg_image = file;
        return null;
      }
      const IMAGE_HEIGHT = 300;
      const IMAGE_WIDTH = 300;
      const th = this;

      const img = new Image();
      img.src = blobURL;
      img.height = IMAGE_HEIGHT;
      img.width = IMAGE_WIDTH;
      img.style.backgroundColor="red";
      img.onload = function() {
        let canvas = document.createElement("canvas");
        canvas.height = IMAGE_HEIGHT;
        canvas.width = IMAGE_WIDTH;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.fillStyle = "#FFFFFF";
        canvasCtx.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvasCtx.drawImage(img, 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvas.toBlob((blob) => {
          const newFile = new File([blob], file.name, {type: "image/png"});
          if (type === "card_front") {
            th.form.card_front = newFile,
            th.card_front_url = URL.createObjectURL(newFile);
          } else if (type === "card_back") {
            th.form.card_back = newFile,
            th.card_back_url = URL.createObjectURL(newFile);
          } else if(type === "card_step2") {
            th.form.card_step2 = newFile,
            th.card_step2_url = URL.createObjectURL(newFile);
          } else if(type === "card_winning") {
            th.form.card_winning = newFile,
            th.card_winning_url = URL.createObjectURL(newFile);
          } else if(type === "loser_popup_image") {
            th.form.loser_popup_image = newFile,
            th.loser_popup_url = URL.createObjectURL(newFile);
          }
        }, "image/png", 1);
      }
    },
    async onScratchCursorImage(e) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      const {height = 0, width = 0} = await getImageHeightWidth(blobURL);
      const IMAGE_HEIGHT = height > 128 ? 128 : height;
      const IMAGE_WIDTH = width > 128 ? 128 : width;
      const th = this;
      const img = new Image();
      img.src = blobURL;
      img.height = IMAGE_HEIGHT;
      img.width = IMAGE_WIDTH;
      img.onload = function() {
        let canvas = document.createElement("canvas");
        canvas.height = IMAGE_HEIGHT;
        canvas.width = IMAGE_WIDTH;
        const canvasCtx = canvas.getContext("2d");
        canvasCtx.fillStyle = "transparent";
        canvasCtx.fillRect(0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvasCtx.drawImage(img, 0, 0, IMAGE_WIDTH, IMAGE_HEIGHT);
        canvas.toBlob((blob) => {
          const newFile = new File([blob], file.name, {type: "image/png"});
          th.form.scratch_cursor_image = newFile;
          th.scratch_cursor_image_url = URL.createObjectURL(newFile);
        }, "image/png", 1);
      }
    },
  },
  mounted() {
    if (_.isObject(this.defaultData) && !_.isEmpty(this.defaultData)) {
      const newFormData = {};
      const newInitialData = {};

      for (const [key, value] of Object.entries(this.defaultData)) {
        if (key && value) {
          if (key.includes("flag")) {
            if (!_.has(this.form, key)) {
              newInitialData[key] = value && !!JSON.parse(value);
            } else {
              newFormData[key] = value && !!JSON.parse(value);
            }
          } else {
            if (!_.has(this.form, key)) {
              newInitialData[key] = this.getValue(value);
            } else {
              newFormData[key] = this.getValue(value);
            }
          }
        }
      }
      this.initialData = {
        ...this.initialData,
        ...newInitialData,
      };
      this.form = {
        ...this.form,
        ...newFormData,
      };
      const {
        bg_image = "", card_back = "", card_front = "", card_step2 = "", card_winning = "",scratch_cursor_image = "", loser_popup_image = "",
        os = "", device = "", browser = "", country = "",script_country = [], script_device = [],
        meta_image = null, campaign_image = null,
      } = this.defaultData;
      this.otherForm.meta_image_url = meta_image;
      this.otherForm.campaign_image_url = campaign_image;
      this.bg_image_url = bg_image;
      this.scratch_cursor_image_url = scratch_cursor_image;
      this.card_back_url = card_back;
      this.card_front_url = card_front;
      this.card_step2_url = card_step2;
      this.card_winning_url = card_winning;
      this.loser_popup_url = loser_popup_image;
      if (!_.isEmpty(os)) {
        this.otherForm.selectedOs = os;
      }
      if (!_.isEmpty(device)) {
        this.otherForm.selectedDevice = device;
      }
      if (!_.isEmpty(browser)) {
        this.otherForm.selectedBrowser = browser;
      }
      if (!_.isEmpty(country)) {
        this.otherForm.selectedCountry = _.map(this.countries, (c) => country.includes(c.value) && c.value).filter(c => c);
      }
      if (!_.isEmpty(script_country)) {
        this.otherForm.scriptSelectedCountry = script_country;
      }
      if (!_.isEmpty(script_device)) {
        this.otherForm.scriptSelectedDevice = script_device;
      }
    }
  },
}
</script>